import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiPostWithAuth, useEntityApiSimple } from "../hooks/useEntityApi";
import { protectedResources } from "../authConfig";
import { Wishlist } from "../models/Wishlist";
import { WishlistItem } from "../models/WishlistItem";
import { Button, Field, Textarea } from "@fluentui/react-components";
import { useDialogStyles } from "../hooks/useDialogStyles";
import { Cart24Regular } from "@fluentui/react-icons";
import { UserContext } from "../components/RequireUser";
import { Link } from "react-router-dom";

export const WishlistItemBuy = () => {
	const { wishlistId, wishlistItemId } = useParams() as { wishlistId: string; wishlistItemId: string };
	const [activityMessage, setActivityMessage] = useState<string | null>("loading...");
	const [itemUnavailable, setItemUnavailable] = useState<boolean>(false);
	const [notes, setNotes] = useState<string>("");
	const user = useContext(UserContext);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items`,
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}`,
	});
	const buyItemApi = useApiPostWithAuth<{ notes: string }, WishlistItem>(
		protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${wishlistItemId}/buy`
	);

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeGet: executeGetItem, isCallingApi: isCallingItemsApi, error: errorItems, entity: item } = wishlistItemsApi;

	const navigate = useNavigate();

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItem().then((result) => {
				const { entity: item } = result;
				if (!item) {
					setItemUnavailable(true);
				} else if (item.bought && item.bought.buyerId != user.userId) {
					setItemUnavailable(true);
				}
			});
		}
		load();
		setActivityMessage(null); // once load is called the isCallingApiXXX variables track the loading :-)
	}, [executeGetList, executeGetItem]);

	const buyItem = async (e?: BaseSyntheticEvent) => {
		e?.preventDefault();
		setActivityMessage("buying...");

		try {
			console.log("Buying item...");
			await buyItemApi.execute({ notes });
		} catch (error) {
			console.log(error);
		}
		// refresh the item
		console.log("Refreshing item...");
		const item = await executeGetItem();
		console.log(item);
	};
	const styles = useDialogStyles();

	if (isCallingListApi || isCallingItemsApi || buyItemApi.isCallingApi) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}
	const error = errorList || errorItems || buyItemApi.error;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}

	if (itemUnavailable) {
		return (
			<>
				<h1 className="heading">
					<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name ?? "unknown"} / buy
				</h1>
				<p>Sorry, the requested item doesn't exist or has already been bought!</p>
				<p>
					<Link to={`/lists/${wishlistId}`}>Back to list</Link>
				</p>
			</>
		);
	}

	if (wishlist && item && item.bought) {
		return (
			<>
				<h1 className="heading">
					<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name} / buy
				</h1>
				<p>Thanks for getting {item.name}!</p>
				<p>
					<Link to={`/lists/${wishlistId}`}>Back to list</Link>
				</p>
			</>
		);
	}

	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / <Link to={`/lists/${wishlistId}`}>{wishlist?.name}</Link> / {item?.name} / buy
			</h1>
			{activityMessage && <div className={styles.savingBanner}>{activityMessage}</div>}
			<p>
				Let us know that you are going to get <span className="item-name">{item?.name}</span> by clicking on the "I'm getting this!" button
				below.
			</p>
			<p>
				If you change your mind, or can't get hold of the item, you can cancel it later (you get an email telling you how to do this, but
				it's not rocket science!).
			</p>
			<p>You can also add a brief message if you would like to!</p>

			<div className="wishlist-item-buy-dialog">
				<Field label="Message" autoFocus orientation="vertical">
					<Textarea rows={5} onChange={(_, data) => setNotes(data.value ?? "")} />
				</Field>
			</div>
			<div className={styles.actions}>
				<Button appearance="primary" icon={<Cart24Regular />} onClick={buyItem}>
					I'm getting this
				</Button>
				<Button appearance="secondary" className={styles.buttonGap} onClick={() => navigate(`/lists/${wishlistItemId}`)}>
					Cancel
				</Button>
			</div>
		</>
	);
};
