import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Card,
	CardFooter,
	CardHeader,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableCellLayout,
	TableColumnDefinition,
	TableColumnId,
	TableHeader,
	TableHeaderCell,
	TableRow,
	Text,
	createTableColumn,
	makeStyles,
	shorthands,
	tokens,
	useTableFeatures,
	useTableSort,
} from "@fluentui/react-components";

import { protectedResources } from "../authConfig";
import { useApiPatchWithAuth, useEntityApiSimple } from "../hooks/useEntityApi";
import {
	Add32Regular,
	Edit32Regular,
	Cart24Regular,
	Eye24Regular,
	EyeOff24Regular,
	Eye16Regular,
	EyeOff16Regular,
	Archive16Regular,
} from "@fluentui/react-icons";
import { Priority } from "../components/Priority";
import { Wishlist } from "../models/Wishlist";
import { WishlistItem, WishlistItemEditModel } from "../models/WishlistItem";

const useStyles = makeStyles({
	card: {
		width: "360px",
		maxWidth: "100%",
		// width: "95%",
		height: "fit-content",
		marginBottom: "1em",
	},
	cardHeader: {
		maxWidth: "100%",
	},
	cardPreview: {
		maxWidth: "100%",
		marginLeft: "1em",
	},
	horizontalCardImage: {
		width: "60px",
		height: "20px",
		marginLeft: "1em",
	},

	headerImage: {
		...shorthands.borderRadius("4px"),
		maxWidth: "44px",
		maxHeight: "44px",
	},

	caption: {
		color: tokens.colorNeutralForeground3,
	},
});

export const WishlistShow = () => {
	const { wishlistId } = useParams() as { wishlistId: string };
	const [loading, setLoading] = useState(true);

	const [excludeBought, setExcludeBought] = useState(false);
	const [includeHidden, setIncludeHidden] = useState(false);

	const wishlistApi = useEntityApiSimple<Wishlist>({
		collectionEndpoint: protectedResources.api.endpoint + "api/wishlists/",
		individualEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}`,
	});
	const wishlistItemsApi = useEntityApiSimple<WishlistItem>({
		collectionEndpoint: protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items?type=available`,
	});

	const { executeGet: executeGetList, isCallingApi: isCallingListApi, error: errorList, entity: wishlist } = wishlistApi;
	const { executeList: executeGetItems, isCallingApi: isCallingItemsApi, error: errorItems, entityList: items } = wishlistItemsApi;

	const itemPatchApi = useApiPatchWithAuth<Partial<WishlistItemEditModel>, WishlistItem>();

	useEffect(() => {
		async function load() {
			// avoid awaiting here to parallelise the calls
			executeGetList().then((list) => {
				if (!list) {
					navigate("/lists");
				}
			});
			executeGetItems();
		}
		load();
		setLoading(false); // once load is called the isCallingApiXXX variables tack the loading :-)
	}, [executeGetList, executeGetItems]);

	const archiveItem = useCallback(
		async function (item: WishlistItem) {
			await itemPatchApi.execute({ archived: true }, protectedResources.api.endpoint + `api/wishlists/${wishlistId}/items/${item.id}`);
			await executeGetItems();
		},
		[itemPatchApi, executeGetItems, wishlistId]
	);

	const navigate = useNavigate();
	const styles = useStyles();

	const columns: TableColumnDefinition<WishlistItem>[] = [
		createTableColumn<WishlistItem>({
			columnId: "name",
			compare: (a, b) => {
				return a.name.localeCompare(b.name);
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "priority",
			compare: (a, b) => {
				return b.priority - a.priority;
			},
		}),
		createTableColumn<WishlistItem>({
			columnId: "price",
			compare: (a, b) => {
				return a.price - b.price;
			},
		}),
	];

	const adminOrOwner = wishlistApi.entity?.userAccessType === "owner" || wishlistApi.entity?.userAccessType === "admin";
	function filterItems(items: WishlistItem[] | null) {
		const predicate = adminOrOwner
			? (item: WishlistItem) => !item.archived && (!item.hidden || includeHidden) && (!item.bought || !excludeBought)
			: (item: WishlistItem) => !item.archived && !item.hidden && !item.bought;
		return items?.filter(predicate);
	}

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
	} = useTableFeatures(
		{
			columns: columns,
			items: filterItems(items) ?? [],
		},
		[
			useTableSort({
				defaultSortState: { sortColumn: "priority", sortDirection: "descending" },
			}),
		]
	);
	const headerSortProps = (columnId: TableColumnId) => ({
		onClick: (e: React.MouseEvent) => {
			toggleColumnSort(e, columnId);
		},
		sortDirection: getSortDirection(columnId),
	});

	const error = errorList || errorItems;
	if (error) {
		return (
			<>
				<div>Error: {error.message}</div>
			</>
		);
	}
	if (isCallingListApi || isCallingItemsApi || loading) {
		return (
			<>
				<div>loading...</div>
			</>
		);
	}

	const rows = sort(getRows());

	return (
		<>
			<h1 className="heading">
				<Link to="/lists">Lists</Link> / {wishlist?.name}&nbsp;
				{adminOrOwner && (
					<Link to={`/lists/${wishlistId}/edit`}>
						<Button icon={<Edit32Regular />}></Button>
					</Link>
				)}
			</h1>
			<div className="wishlist-show-bought-items-link">
				<Link to={`/lists/${wishlistId}/bought`}>Click here</Link> for items you've bought from this list
			</div>
			{adminOrOwner && (
				<div className="wishlist-show-archived-items-link">
					<Link to={`/lists/${wishlistId}/archived`}>Click here</Link> for archived items
				</div>
			)}

			{adminOrOwner && (
				<>
					<div className="options">
						<div className="options-title">Options</div>
						<Checkbox checked={excludeBought} onChange={() => setExcludeBought(!excludeBought)} label="Hide bought items?" />
						<Checkbox checked={includeHidden} onChange={() => setIncludeHidden(!includeHidden)} label="Include hidden items in list?" />
						{excludeBought ? (
							<></>
						) : (
							<div>NOTE: The list below shows items regardless of whether they have been bought to avoid spoilers 🙂</div>
						)}
					</div>
				</>
			)}
			<div className="wishlist-show-list">
				<h3>
					Items&nbsp;
					{adminOrOwner && (
						<Link to={`/lists/${wishlistId}/items/add`} className="add-new">
							<Button icon={<Add32Regular />}>Add new</Button>
						</Link>
					)}
				</h3>
				{/* Default (table) content goes here */}
				{rows?.length === 0 ? (
					<>No items in this wishlist.</>
				) : (
					<Table sortable>
						<TableHeader>
							<TableRow>
								<TableHeaderCell {...headerSortProps("name")}>Name</TableHeaderCell>
								<TableHeaderCell {...headerSortProps("priority")}>Priority</TableHeaderCell>
								{adminOrOwner && <TableHeaderCell>Visible</TableHeaderCell>}
								<TableHeaderCell {...headerSortProps("price")}>Price</TableHeaderCell>
								<TableHeaderCell></TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{rows?.map((row) => (
								<TableRow key={row.item.id}>
									<TableCell>
										<TableCellLayout appearance="primary">
											<Link to={`/lists/${wishlistId}/items/${row.item.id}`}>{row.item.name}</Link>
										</TableCellLayout>
									</TableCell>
									<TableCell>
										<Priority value={row.item.priority} />
									</TableCell>
									{adminOrOwner && <TableCell>{row.item.hidden ? <EyeOff24Regular /> : <Eye24Regular />}</TableCell>}
									<TableCell>{row.item.price ? `£${row.item.price}` : ""}</TableCell>
									<TableCell>
										<Link to={`/lists/${wishlistId}/items/${row.item.id}/buy`}>
											<Button icon={<Cart24Regular />}>Get this...</Button>
										</Link>
										{adminOrOwner && (
											<>
												<Button icon={<Archive16Regular />} onClick={() => archiveItem(row.item)}>
													Archive
												</Button>
												<Link to={`/lists/${wishlistId}/items/${row.item.id}/edit`}>
													<Button icon={<Edit32Regular />}>Edit</Button>
												</Link>
											</>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				)}
			</div>
			<div className="wishlist-show-list-small">
				{/* Rendering for narrower devices/windows goes here */}
				{adminOrOwner && (
					<Link to={`/lists/${wishlistId}/items/add`} className="add-new">
						<Button icon={<Add32Regular />}>Add new</Button>
					</Link>
				)}

				{rows?.map((row) => (
					<Card className={styles.card} orientation="vertical" key={row.item.id}>
						<CardHeader
							header={
								<Link key={row.item.id} to={`/lists/${wishlistId}/items/${row.item.id}`} className="wishlist-show-item-small">
									<Text weight="semibold" size={400}>
										{row.item.name}
									</Text>
								</Link>
							}
							className={styles.cardHeader}
						></CardHeader>
						<CardFooter>
							<Link key={row.item.id} to={`/lists/${wishlistId}/items/${row.item.id}`} className="wishlist-show-item-small">
								<Priority value={row.item.priority} />
								<Text size={300} className="wishlist-show-item-small-price">
									&pound;{row.item.price}
								</Text>
							</Link>
							<Link to={`/lists/${wishlistId}/items/${row.item.id}/buy`} className="wishlist-show-item-small-buy">
								<Button icon={<Cart24Regular />} title="Get item"></Button>
							</Link>
							{adminOrOwner && (
								<>
									<Button
										className="wishlist-show-item-archive"
										icon={<Archive16Regular />}
										onClick={() => archiveItem(row.item)}
										title="Archive item"
									></Button>
									<span className="wishlist-show-item-small-eye">{row.item.hidden ? <EyeOff16Regular /> : <Eye16Regular />}</span>
								</>
							)}
						</CardFooter>
					</Card>
				))}
			</div>
		</>
	);
};
